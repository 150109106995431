




























































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { IDatatableOptions } from "@/api/types";
import { KomisActionButton, KomisAlert, KomisButton, KomisDialog, KomisTable, LetterFilter } from "@iway/spa-components";
import {
    deleteStimulus,
    EStimulusStateCodes,
    getStimulus,
    ICertification,
    IStimulusDatatableResponse,
    IStimulusState,
} from "@/api/stimulus.api";
import { IStimulusListFilterOptions, stimulusFilter } from "@/components/stimulus-list/stimulusFilter";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import { RouteNames } from "@/router";
import { getStimulusStates } from "@/api/stimulus-states";
import { USER_ROLE, userHasRole, VALIDATION_TYPE } from "@/helpers/user";
import { ETypeCodes, getPdfLink } from "@/helpers/linkBuilder";
import { userIsOrganizationAdmin } from "@/api/organization.api";

@Component({
    components: {
        KomisTable,
        KomisAlert,
        LetterFilter,
        KomisActionButton,
        KomisDialog,
        KomisButton,
    },
})
export default class StimulusTable extends Vue {
    @Prop(Boolean) hasPermission!: boolean;

    allStimulusStates: IStimulusState[] = [];
    RouteNames = RouteNames;
    getNetgrifUrlByParams = getNetgrifUrlByParams;
    error = false;
    isOrganizationAdmin: boolean | null = null;
    stimulusDatatable: IDatatableOptions & { items: ICertification[] } = {
        headers: [],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    /*
    export interface IStimulus {
    id: string; // id stimulu
    code: number;
    organization: IOrganization;
    state: IStimulusState;
    createDate: string; // iso date; Dátum vytvorenia žiadosti (skStartDate - najstarší záznam - keď bola žiadosť vytvorená)
    sendDate: string; // iso date; Dátum odoslania (skStartDate - najmladší záznam so stavom Podaná)
    modifyDate: string; // iso date; Datum modifikacie formularovych dat (skModifyDate)
    uri: string | null; // (skURI) Referencia na zobrazenie vykazu v Netgrif (id procesu) - moze byt NULL (tj. neexistuje v Netgrif, migrovany zo stareho systemu)
}
     */
    get tableHeaders(): DataTableHeader[] {
        const headers = [
            {
                text: this.$i18n.t("stimulus.datatable.create_date") as string,
                sortable: true,
                value: "modifyDate",
                width: "190px",
            },
            {
                text: this.$i18n.t("stimulus.datatable.send_date") as string,
                sortable: true,
                value: "postingDate",
                width: "190px",
            },
            {
                text: this.$i18n.t("stimulus.datatable.state") as string,
                sortable: true,
                value: "state.name",
                width: "190px",
            },
            {
                text: this.$i18n.t("stimulus.datatable.actions") as string,
                sortable: false,
                value: "actions",
            },
        ];
        if (!this.organizationRoute) {
            headers.unshift({
                text: this.$i18n.t("stimulus.datatable.organization") as string,
                sortable: true,
                value: "organization.name",
                width: "280px",
            });
        }

        return headers;
    }

    /*
      ROUTES  ==========================================================
     */
    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationStimulusList;
    }

    get organizationId(): string | null {
        return this.organizationRoute ? this.$route.params.organizationId : null;
    }

    getEvaluationsListRoute(item: ICertification) {
        return this.organizationRoute
            ? { name: RouteNames.OrganizationStimulusEvaluations, params: { organizationId: item.organization.id, id: item.id } }
            : { name: RouteNames.StimulusEvaluations, params: { id: item.id } };
    }

    getDetailRoute(item: ICertification) {
        return this.organizationRoute
            ? { name: RouteNames.OrganizationStimulusDetail, params: { organizationId: item.organization.id, id: item.id } }
            : { name: RouteNames.StimulusDetail, params: { id: item.id } };
    }

    getDocumentsListRoute(item: ICertification) {
        return this.organizationRoute
            ? { name: RouteNames.OrganizationDocuments, params: { organizationId: item.organization.id, id: item.id } }
            : { name: RouteNames.Documents, params: { id: item.id } };
    }

    getEditFormRoute(item: ICertification) {
        window.location.assign(this.getFormLink(item));
    }

    getPdfRoute(item: ICertification) {
        window.open(getPdfLink(item.id, ETypeCodes.Certificates));
    }

    getFormLink(detailItem: ICertification) {
        console.log("detail", detailItem);
        if (
            this.$route.params.organizationId &&
            (detailItem.state.code === EStimulusStateCodes.Rozpracovany ||
                detailItem.state.code === EStimulusStateCodes.VratenyNaDoplnenie)
        ) {
            if(detailItem.uri) {
                return getNetgrifUrlByParams(
                    "zobrazenie_rozpracovanej_ziadosti_hodnotenia",
                    "SKCRIS-025",
                    { caseId: detailItem.uri as string, orgId: this.$route.params.organizationId },
                    this.$store.state.language.value,
                ) as string;
            } else {
                return getNetgrifUrlByParams(
                    "vytvorenie_ziadosti_hodnotenia",
                    "SKCRIS-025",
                    { orgId: this.$route.params.organizationId },
                    this.$store.state.language.value,
                ) as string + "?certificationId=" + detailItem.id;
            }
            
        } else {
            return getPdfLink(detailItem.id, ETypeCodes.Certificates);
        }
    }
    // ================================== END ROUTES ========================

    async created() {
        this.allStimulusStates = await getStimulusStates();
        if (this.organizationId) {
            this.isOrganizationAdmin = await userIsOrganizationAdmin(this.organizationId);
        }
    }

    mounted(): void {
        this.loadStimulus(this.stimulusDatatable.options);

        this.stimulusDatatable.headers = this.tableHeaders;
    }

    // ROLES
    get canViewEvaluations() {
        return userHasRole([USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.Hodnotitel], VALIDATION_TYPE.OneOf);
    }

    // is organization admin and state = Rozpracovana, Vratena na doplnenie
    isAllowedModifying(item: ICertification) {
        const stateCode = this.allStimulusStates.find((state) => item.state.id === state.id)?.code || false;
        return (
            this.isOrganizationAdmin &&
            this.hasPermission &&
            stateCode &&
            [EStimulusStateCodes.Rozpracovany, EStimulusStateCodes.VratenyNaDoplnenie].includes(stateCode)
        );
    }

    // is organization state = OdoslanyNaHodnotenie, Schvaleny, SchvalenySNutnouVerifikaciou, Odobrany, Zamietnuty
    showEvaluationList(item: ICertification) {
        const stateCode = this.allStimulusStates.find((state) => item.state.id === state.id)?.code || false;
        return (
            stateCode &&
            [
                EStimulusStateCodes.OdoslanyNaHodnotenie,
                EStimulusStateCodes.Schvaleny,
                EStimulusStateCodes.SchvalenySNutnouVerifikaciou,
                EStimulusStateCodes.Odobrany,
                EStimulusStateCodes.Zamietnuty,
            ].includes(stateCode as EStimulusStateCodes)
        );
    }

    get filter(): IStimulusListFilterOptions {
        return stimulusFilter;
    }
    @Watch("filter", { deep: true })
    onFilterChanged() {
        this.loadStimulus(this.stimulusDatatable.options);
    }

    processDatatableResponse(datatableResponse: IStimulusDatatableResponse): void {
        this.stimulusDatatable.items = datatableResponse.data;
        this.stimulusDatatable.count = datatableResponse.count;
    }

    loadStimulus(paginationOptions: DataOptions | undefined = undefined): void {
        this.stimulusDatatable.loading = true;
        this.error = false;

        if (paginationOptions) {
            getStimulus(paginationOptions, this.organizationId)
                .then((datatableResponse) => {
                    console.log(datatableResponse);
                    this.processDatatableResponse(datatableResponse);
                })
                .catch((err: any) => {
                    console.error("loadStimulus -> getStimulus() error", err);
                    this.error = true;
                })
                .finally(() => (this.stimulusDatatable.loading = false));
        }
    }

    onDatatableOptionsChanged(options: DataOptions): void {
        this.loadStimulus(options);
    }

    deleteStimulus(stimulus: ICertification) {
        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(
                this.$i18n
                    .t("modals.confirm_delete.stimulus", {
                        description: stimulus.organization.name
                    })
                    .toString(),
            )
            .then(() => {
                deleteStimulus(stimulus.id).then(() => {
                    this.loadStimulus(this.stimulusDatatable.options);
                });
            });
    }
}
