






















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { KomisAutocomplete, KomisButton, KomisDialog } from "@iway/spa-components";
import { debounce } from "lodash";
import {
    IOrganization,
    searchOrganizations,
    IResourceModel,
    createOrganization,
    getCertOrgRoles,
    IAttachmentType,
} from "@/api/stimulus.api";

const initialModel = (): IResourceModel => ({
    id: null,
    name: null,
    relation: {
        id: null,
        name: null,
    },
});

@Component({
    components: { KomisButton, KomisDialog, KomisAutocomplete },
})
export default class AddOrganizationModal extends Vue {
    @Prop({ type: String, required: true }) certificationId!: string;

    organizationsLoading = false;
    rolesLoading = true;
    organizations: IOrganization[] = [];
    organizationInput = "";
    organizationSelected = false;
    roles: IAttachmentType[] = [];
    organization: IResourceModel = initialModel();

    created() {
        this.getOrganizations = debounce(this.getOrganizations, 500);
        this.getRoles = debounce(this.getRoles, 150);
    }

    get isValid() {
        return this.organization.id && this.organization.relation.id;
    }

    mounted() {
        this.getRoles();
    }

    getOrganizations(filter = null) {
        this.organizations.splice(0);
        this.organizationsLoading = true;
        searchOrganizations(filter)
            .then((organizations) => (this.organizations = organizations))
            .finally(() => (this.organizationsLoading = false));
    }

    getRoles() {
        this.rolesLoading = true;
        getCertOrgRoles()
            .then((relations) => {
                this.roles = relations.slice(1); // KOMISSDBCK-1061
                console.log(relations);
            })
            .finally(() => (this.rolesLoading = false));
    }

    addOrganization() {
        if (this.organization.id && this.organization.relation.id) {
            return createOrganization(this.certificationId, this.organization).then(() => {
                this.showSuccessInfo();
                this.resetModal();
                return Promise.resolve();
            });
        }
    }

    showSuccessInfo() {
        this.$store.dispatch("showNotification", {
            type: "success",
            title: this.$i18n.t("modals.addOrganizationModal.notifications.created"),
            text: this.$i18n.t("modals.addOrganizationModal.notifications.createdOrgRelation"),
        });
    }

    resetModal() {
        this.organization = initialModel();
    }

    public async open() {
        this.organizations.splice(0);
        this.organizationSelected = false;

        await (this.$refs.komisDialog as KomisDialog<any>).open().then(() => {
            return this.addOrganization();
        });
    }

    @Watch("organizationInput")
    onOrganizationInputChanged(search, searchOld) {
        console.log("search researcherInput ===== ", !this.organizationSelected, search, searchOld);
        if (!this.organizationSelected && search && search.length > 1 && search !== searchOld) {
            this.getOrganizations(search);
        }
    }
}
