import axios, { AxiosResponse } from "axios";
import store from "@/store";

const api = axios.create({ withCredentials: true });

/*
    TODO:
     my notes
     http://skcris.komis-dev.cvtisr.sk/orgapi/api/v1/stimulus/categories/smReviewStatus
 */

api.interceptors.request.use((request) => {
    request.baseURL = store.state.envParams!["komis.vue.config.researcher.api.url"] || null;
    request.params = { ...request.params, langCode: store.state.language.valueForApi };
    return request;
});

export interface IUser {
    id: string;
    name: string;
    email: string;
}

export const getOrganizationAdmins = (organizationId: string): Promise<IUser[]> => {
    return api
        .get(`/api/v1/users/organizations/${organizationId}/admin`)
        .then((response: AxiosResponse<IUser[]>) => response.data);
};

const organizations: Record<string, boolean> = {};

export const userIsOrganizationAdmin = async (organizationId: string): Promise<boolean> => {
    if (!Object.keys(organizations).includes(organizationId)) {
        const admins = await getOrganizationAdmins(organizationId);

        if (!store.state.userLoaded) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            return userIsOrganizationAdmin(organizationId);
        } else {
            organizations[organizationId] = admins.findIndex((admin) => admin.id === store.state.loggedUser.userId) !== -1;
            return Promise.resolve(organizations[organizationId]);
        }
    } else {
        return Promise.resolve(organizations[organizationId]);
    }
};
