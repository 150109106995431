


























































import { Component, Prop, Vue, Watch, } from "vue-property-decorator";
import { KomisAlert, KomisButton, KomisSelect, KomisTextArea, KomisDateField, KomisTextField } from "@iway/spa-components";
import { getStimulusStates } from "@/api/stimulus-states";
import { IStimulusState, setStimulusState, IStateChangeItem, EStimulusStateCodes } from "@/api/stimulus.api";
import { RouteNames } from "@/router";

@Component({
    components: { KomisButton, KomisSelect, KomisTextField, KomisTextArea, KomisAlert, KomisDateField },
})
export default class StimulusStateChange extends Vue {
    @Prop({ required: true, type: Array }) allowedStates!: string[];
    @Prop({ required: true, type: String }) stimulusId!: string;

    allStates: IStimulusState[] = [];
    allStatesLoading = true;
    extendedStates: IStimulusState[] = [];
    componentKey = 0;

    state: string | null = null;
    stateChangeItem: IStateChangeItem = {
        certificateNumber: "", //ceCert.ceCertNum
        issueDate: "", //ceCert.ceIssueDate
        validFrom: "", //ceCert.ceValidFrom
        validUntil: "", //ceCert.ceValidUntil
        comment: "", //ceCertState.ceReason
    };

    saving = false;
    savingError = false;
    noReviewError = false;

    async mounted() {
        this.allStates = await getStimulusStates().finally(() => this.allStatesLoading = false);
        this.extendedStates = this.allStates.filter((state) => (state.code == EStimulusStateCodes.Schvaleny || state.code == EStimulusStateCodes.SchvalenySNutnouVerifikaciou));
        console.log("mounted:", this.allStates, this.extendedStates);
    }

    get visible(): boolean {
        return this.extendedStates.filter((state) => (state.id == this.state)).length ? true : false
    }

    get states(): IStimulusState[] {
        return this.allStates.filter((state) => this.allowedStates.includes(state.id));
    }

    save() {
        if (this.state) {
            this.saving = true;
            this.savingError = false;

            setStimulusState(this.stimulusId, this.state, this.stateChangeItem)
                .then((res) => { 
                    if(res.data.newCertificateId) {
                        console.log("New res: ", res.data.newCertificateId);
                        this.$router.push({ name: RouteNames.StimulusDetail, params: { id: res.data.newCertificateId } })
                    } else {
                        this.$emit("refresh");
                    }
                    
                })
                .catch((res) => (res.response.data.code === "MISSING_REVIEWS" ? this.noReviewError = true : this.savingError = true))
                .finally(() => { 
                    this.saving = false;
                })
        }
    }

    @Watch("state")
    onFilterChanged() {
        console.log("State: ", this.state, this.visible);
        this.componentKey++;
    }

    @Watch("stateChangeItem", {deep: true})
    onStateChangeItem() {
        this.savingError = false;
        this.noReviewError = false;
    }
}
