import Vue, { CreateElement } from "vue";
import singleSpaVue from "single-spa-vue";

import "./assets/scss/style.module.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./plugins/register-hooks";
import axios, { AxiosError } from "axios";
import { RouterLink } from "vue-router";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Version from "raw-loader!./version.txt";

console.info("=============================");
console.info("VUE APP VERSION: ", Version || "n/a"); // current commit hash
console.info("=============================");

Vue.config.productionTip = false;
Vue.component("router-link", RouterLink);

axios.interceptors.response.use((response) => response, manageErrorConnection);

function toIsoString(date: Date) {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num: number) {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? "0" : "") + norm;
        };

    return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ":" +
        pad(tzo % 60)
    );
}

function manageErrorConnection(err: AxiosError<any>) {
    const errObj: ApiErrorObject = {} as ApiErrorObject;
    const notificationObj: NotificationObject = {} as NotificationObject;

    if (err.response) {
        console.log("error response:", err.response);
        if (typeof err.response.data === "object" && err.response.data !== null && Object.keys(err.response.data).length !== 0) {
            if (err.response.data.code) errObj.code = err.response.data.code;
            if (err.response.data.message) errObj.message = err.response.data.message;
            if (err.response.data.detail) errObj.detail = err.response.data.detail;
            if (err.response.data.timestamp) errObj.timestamp = err.response.data.timestamp;
            if (err.response.data.id) errObj.id = err.response.data.id;
        } else if (typeof err.response.data === "string" && err.response.data !== null && err.response.data.length > 0) {
            errObj.code = "KOMIS-RESPONSE-IS-STRING";
            errObj.message = "Status: " + err.response.status.toString();
            errObj.detail = err.response.data;
            errObj.timestamp = toIsoString(new Date());
            errObj.id = "no-id";
        } else {
            errObj.code = "KOMIS-RESPONSE-IS-NULL";
            errObj.message = "Status: " + err.response.status.toString();
            errObj.detail = i18n.t("axios.interceptor.error.noResponseData.detail");
            errObj.timestamp = toIsoString(new Date());
            errObj.id = "no-id";
        }

        notificationObj["type"] = "error";
    } else {
        errObj.code = "KOMIS-SERVER-NOT-AVAILABLE";
        errObj.message = i18n.t("axios.interceptor.error.serverNotAvailable.message");
        errObj.detail = i18n.t("axios.interceptor.error.serverNotAvailable.detail");
        errObj.timestamp = toIsoString(new Date());
        errObj.id = "no-id";

        notificationObj["type"] = "error";
    }

    notificationObj["title"] = errObj.message;
    notificationObj["text"] = errObj.detail;
    notificationObj["contentToCopy"] = errObj;

    const duplicate = store.state.notifications.filter((e) => e.title === notificationObj.title).length > 0;
    if (!duplicate) store.dispatch("showNotification", notificationObj);

    return Promise.reject(err);
}

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        render(h: CreateElement) {
            return h(App, {
                props: {
                    // single-spa props are available on the "this" object. Forward them to your component as needed.
                    // https://single-spa.js.org/docs/building-applications#lifecycle-props
                    // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
                    /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
                },
            });
        },
        router,
        store,
        vuetify,
        i18n,
    },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
