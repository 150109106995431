













import { Component, Vue } from "vue-property-decorator";
import { KomisHeading1, KomisHeading2, KomisParagraph } from "@iway/spa-components";

@Component({
    components: { KomisHeading1, KomisHeading2, KomisParagraph },
})
export default class Error403 extends Vue {
    mounted(): void {
        //
    }
}
