















import { Component, Vue } from "vue-property-decorator";
import { KomisHeading1, KomisHeading2, KomisParagraph } from "@iway/spa-components";
import { RouteNames } from "@/router";

@Component({
    components: { KomisHeading1, KomisHeading2, KomisParagraph },
})
export default class Error404 extends Vue {
    RouteNames = RouteNames;

    mounted(): void {
        //
    }
}
