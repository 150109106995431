













import { Component } from "vue-property-decorator";
import { KomisButton, KomisContentHeader } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import EvaluationsDetail from "@/components/evaluations/EvaluationsDetail.vue";

@Component({
    components: { EvaluationsDetail, KomisContentHeader, KomisButton },
})
export default class EvaluationsDetailView extends BaseView {
    get stimulusEvaluationsRoute() {
        return this.$route.name === RouteNames.StimulusEvaluationsDetail;
    }

    mounted() {
        if (this.stimulusEvaluationsRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.stimulus_list").toString(),
                to: {
                    name: RouteNames.StimulusList,
                    params: { id: this.$route.params.organizationId },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: {
                    name: RouteNames.StimulusDetail,
                    params: { organizationId: this.$route.params.organizationId, id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list").toString(),
                to: {
                    name: RouteNames.StimulusEvaluations,
                    params: { id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.detail").toString(),
                disabled: false,
                link: false,
                exact: true,
            });
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list").toString(),
                to: { name: RouteNames.AllEvaluations },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.detail").toString(),
                disabled: false,
                link: false,
                exact: true,
            });
        }
    }

    get backButtonRoute() {
        return this.$route.params.id
            ? { name: RouteNames.StimulusEvaluations, params: { id: this.$route.params.id } }
            : { name: RouteNames.AllEvaluations };
    }
}
