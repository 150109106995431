





































































import { Component, Watch } from "vue-property-decorator";
import { KomisButton, KomisContentHeader, KomisSelect, KomisAlert } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import EvaluationsDetail from "@/components/evaluations/EvaluationsDetail.vue";
import { getStimulusDetail, ICertificationDetail } from "@/api/stimulus.api";

import {
    IStimulusEvaluator,
    getSmReviewTypes,
    IAttachmentType,
    getTaskProjects,
    IProject,
    getSmProjectTemplates,
    ITask,
    postTask,
} from "@/api/stimulus.api";
import { getStimulusEvaluators } from "@/api/stimulus-evaluators";

@Component({
    components: { EvaluationsDetail, KomisContentHeader, KomisButton, KomisSelect, KomisAlert },
})
export default class EvaluationsDetailView extends BaseView {
    error = false;
    evaluatorsLoading = true;
    smReviewTypesLoading = true;
    taskProjectsLoading = true;
    smProjectTemplatesLoading = true;
    allEvaluators: IStimulusEvaluator[] = [];
    allSmReviewTypes: IAttachmentType[] = [];
    allSmProjectTemplates: IAttachmentType[] = [];
    allTaskProjects: IProject[] = [];
    evaluatorname = "";
    smReviewType = "";
    smProjectTemplate = "";
    task: ITask = {
        id: null,
        formId: "",
        evaluatorTypeId: "",
        evaluatorId: "",
        templateId: "",
        templateCode: "",
        uri: "",
    };

    stimulusDetail: ICertificationDetail | false = false;

    formValid = false;

    get stimulusEvaluationsRoute() {
        return this.$route.name === RouteNames.StimulusEvaluations;
    }

    mounted() {
        this.breadcrumbItems.push({
            text: this.$i18n.t("stimulus.list") as string,
            to: {
                name: RouteNames.StimulusList,
            },
            disabled: false,
            link: true,
            exact: true,
        });

        this.breadcrumbItems.push({
            text: this.$i18n.t("stimulus.detail") as string,
            to: {
                name: RouteNames.StimulusDetail,
                params: { id: this.$route.params.id },
            },
            disabled: false,
            link: true,
            exact: true,
        });

        this.breadcrumbItems.push({
            text: this.$i18n.t("evaluations.list") as string,
            to: {
                name: RouteNames.StimulusEvaluations,
                params: { id: this.$route.params.id },
            },
            disabled: false,
            link: true,
            exact: true,
        });
    }

    created() {
        // load stimulus states
        this.smReviewTypesLoading = true;
        getSmReviewTypes()
            .then((smReviewTypes) => {
                console.log("smReviewTypes", smReviewTypes);
                this.allSmReviewTypes = smReviewTypes;
            })
            .finally(() => (this.smReviewTypesLoading = false));

        this.evaluatorsLoading = true;
        getStimulusEvaluators()
            .then((evaluators) => {
                this.allEvaluators = evaluators;
            })
            .finally(() => (this.evaluatorsLoading = false));

        this.smProjectTemplatesLoading = true;
        getSmProjectTemplates()
            .then((smProjectTemplates) => {
                this.allSmProjectTemplates = smProjectTemplates;
            })
            .finally(() => (this.smProjectTemplatesLoading = false));

        getStimulusDetail(this.$route.params.id)
            .then((stimulusDetail) => (this.stimulusDetail = stimulusDetail))
            .finally(() => console.log("StimulusDetail:", this.stimulusDetail));
    }

    get evaluators(): IStimulusEvaluator[] {
        return this.allEvaluators;
    }

    get smReviewTypes(): IAttachmentType[] {
        return this.allSmReviewTypes;
    }

    get smProjectTemplates(): IAttachmentType[] {
        return this.allSmProjectTemplates;
    }

    get backButtonRoute() {
        return { name: RouteNames.AllEvaluations };
    }

    saveTask() {
        if (this.stimulusDetail) {
            if (this.task.templateId) {
                this.task.templateCode = this.smProjectTemplates.filter((item) => item.id === this.task.templateId)[0]?.code;
            }

            postTask([this.task], this.$route.params.id).then((response) => {
                if (response.success) {
                    this.$router.push({ name: RouteNames.StimulusEvaluations });
                } else if (response.error) {
                    console.log("Error:", response.error);
                    this.error = true;
                }
            });
        }
    }

    @Watch("task", { deep: true })
    onDataChanged(task: ITask): void {
        this.formValid = false;
        if (task.evaluatorId && task.evaluatorTypeId && task.templateId) {
            this.formValid = true;
        }
    }
}
