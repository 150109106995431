import Vue from "vue";
import { AxiosResponse } from "axios";
import { api, IStimulusEvaluator } from "@/api/stimulus.api";

const stimulusEvaluators = Vue.observable({ evaluators: [] as IStimulusEvaluator[] });

let pendingPromise: Promise<any> | null = null;

export const getStimulusEvaluators = async (): Promise<IStimulusEvaluator[]> => {
    if (!stimulusEvaluators.evaluators.length) {
        pendingPromise = api
            .get(`/api/v1/certification/evaluators`)
            .then((response: AxiosResponse<IStimulusEvaluator[]>) => {
                stimulusEvaluators.evaluators = response.data;
                return stimulusEvaluators.evaluators;
            })
            .finally(() => (pendingPromise = null));
        return pendingPromise;
    } else {
        return stimulusEvaluators.evaluators;
    }
};
