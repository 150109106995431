







































































import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteTask, getEvaluationPdfLink, getEvaluationsDetail, IEvaluationDetail } from "@/api/stimulus.api";
import { EEvaluationStates } from "@/api/evaluations-states";
import { KomisAlert, KomisButton, KomisDialog, KomisKeyValueList } from "@iway/spa-components";
import { RouteNames } from "@/router";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import EvaluationsStateChange from "@/components/evaluations/EvaluationsStateChange.vue";
import { ETypeCodes, getPdfLink } from "@/helpers/linkBuilder";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";

@Component({
    methods: { getEvaluationPdfLink },
    components: { EvaluationsStateChange, KomisKeyValueList, KomisButton, KomisAlert, KomisDialog },
})
export default class EvaluationsDetail extends Vue {
    @Prop({ required: true, type: String }) evaluationId!: string;
    @Prop(String) evaluatorId!: boolean;

    EEvaluationStates = EEvaluationStates;
    RouteNames = RouteNames;
    evaluationsDetail: IEvaluationDetail | false = false;
    loading = true;
    error = false;

    get stimulusEvaluationsRoute() {
        return this.$route.name === RouteNames.StimulusEvaluationsDetail;
    }

    get readonly() {
        return false; // userHasRole(USER_ROLE.ClenKomisieStimulyVav);
    }

    get canDelete() {
        return userHasRole(USER_ROLE.SpravcaAgendyStimulyVav);
    }

    canEdit() {
        if (this.evaluationsDetail) {
            return userHasRole(USER_ROLE.Hodnotitel) && this.$store.state.loggedUser.userId == this.evaluationsDetail.reviewerId;
        } else {
            return false;
        }
    }

    get documentsListRoute() {
        return this.stimulusEvaluationsRoute
            ? {
                  name: RouteNames.StimulusEvaluationsDocuments,
                  params: { id: this.$route.params.id, evaluationId: this.evaluationId, evaluatorId: this.evaluatorId },
              }
            : { name: RouteNames.EvaluationsDocuments, params: { evaluationId: this.evaluationId, evaluatorId: this.evaluatorId } };
    }

    certFormRoute() {
        if (this.evaluationsDetail) {
            window.open(getEvaluationPdfLink(this.evaluationId));
        }
    }

    netgrifFormRoute() {
        if (this.evaluationsDetail) {
            window.location.assign(
                getNetgrifUrlByParams(
                    "zobrazenie_hodnotenia_sposobilosti",
                    "SKCRIS-026",
                    { orgId: this.evaluationsDetail.organization.id, caseId: this.evaluationsDetail.uri },
                    this.$store.state.language.value,
                ) as string,
            );
        }
    }

    stimulusPdfRoute() {
        window.open(getPdfLink(this.evaluationsDetail ? this.evaluationsDetail.requestId : "", ETypeCodes.Certificates));
    }

    get detailInfoItems() {
        if (!this.evaluationsDetail) {
            return [];
        }

        return [
            { key: this.$i18n.t("detail.organization").toString(), value: this.evaluationsDetail.organization.name },
            { key: this.$i18n.t("evaluations.datatable.creation_date").toString(), value: this.evaluationsDetail.creationDate },
            { key: this.$i18n.t("evaluations.datatable.state").toString(), value: this.evaluationsDetail.requestState.name },
            { key: this.$i18n.t("evaluations.datatable.evaluator").toString(), value: this.evaluationsDetail.reviewer },
        ];
    }

    removeTask() {
        if (!this.evaluationsDetail) {
            return;
        }

        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(this.$i18n.t("modals.confirm_delete.task").toString())
            .then(() => {
                if (this.evaluationsDetail)
                    deleteTask(this.evaluationsDetail.id, this.evaluationsDetail.uri).then(() => {
                        this.$router.push({ name: RouteNames.StimulusEvaluations });
                    });
            });
    }

    async created() {
        this.loading = true;

        getEvaluationsDetail(this.evaluationId)
            .then((evaluationsDetail) => (this.evaluationsDetail = evaluationsDetail))
            .catch(() => (this.error = true))
            .finally(() => (this.loading = false));
    }
}
