


































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader, DataOptions } from "vuetify";
import { IDatatableOptions } from "@/api/types";
import {
    getCertificationOrganizations,
    ICertificationOrganizationsDatatableResponse,
    deleteCertOrgRelation,
    ICertificationOrganization,
    EStimulusStateCodes,
} from "@/api/stimulus.api";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import { KomisActionButton, KomisAlert, KomisButton, KomisTable, KomisHeading4, KomisDialog } from "@iway/spa-components";
import AddOrganizationModal from "@/components/stimulus-detail/AddOrganizationModal.vue";

@Component({
    components: {
        KomisTable,
        KomisAlert,
        KomisActionButton,
        KomisButton,
        KomisHeading4,
        KomisDialog,
        AddOrganizationModal,
    },
})
export default class CertificationOrganizationsTable extends Vue {
    @Prop(String) certificationId!: string;
    @Prop(String) lastState!: string;
    error = false;
    organizationsDatatable: IDatatableOptions & { items: ICertificationOrganization[] } = {
        headers: [],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: false,
        },
    };

    get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$i18n.t("detail.organizations.datatable.name") as string,
                sortable: true,
                value: "name",
            },
            {
                text: this.$i18n.t("detail.organizations.datatable.relation") as string,
                sortable: false,
                value: "relation.name",
            },
            {
                text: this.$i18n.t("detail.organizations.datatable.actions") as string,
                sortable: false,
                value: "actions",
            },
        ];
    }

    get canAddResource() {
        const isStateValid = this.lastState === EStimulusStateCodes.Schvaleny || this.lastState === EStimulusStateCodes.SchvalenySNutnouVerifikaciou;
        return isStateValid && userHasRole([USER_ROLE.SpravcaAgendyStimulyVav]);
    }

    mounted(): void {
        this.organizationsDatatable.headers = this.tableHeaders;
        this.loadCertificateOrganizations(this.organizationsDatatable.options);
    }

    processDatatableResponse(datatableResponse: ICertificationOrganizationsDatatableResponse): void {
        this.organizationsDatatable.items = datatableResponse.data;
        this.organizationsDatatable.count = datatableResponse.count;
    }

    loadCertificateOrganizations(paginationOptions: DataOptions | undefined = undefined): void {
        this.organizationsDatatable.loading = true;
        this.error = false;

        if (paginationOptions) {
            getCertificationOrganizations(this.certificationId, paginationOptions)
                .then((datatableResponse) => {
                    this.processDatatableResponse(datatableResponse);
                })
                .catch((err: any) => {
                    console.error("loadCertificateOrganizations -> getCertificationOrganizations() error", err);
                    this.error = true;
                })
                .finally(() => (this.organizationsDatatable.loading = false));
        }
    }

    onDatatableOptionsChanged(options: DataOptions): void {
        this.loadCertificateOrganizations(options);
    }

    deleteCertificationOrganization(item: ICertificationOrganization) {
        (this.$refs.confirmDeleteDialog as KomisDialog<string>).open(this.$i18n.t("modals.confirm_delete.org").toString()).then(() => {
            deleteCertOrgRelation(this.certificationId, item).then(() => {
                this.loadCertificateOrganizations(this.organizationsDatatable.options);
            });
        });
    }

    async addResource() {
        console.log(typeof (this.$refs.addOrgModal as AddOrganizationModal).open);
        (this.$refs.addOrgModal as AddOrganizationModal).open().then(() => {
            this.loadCertificateOrganizations(this.organizationsDatatable.options);
        });
    }
}
