// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@iway/spa-components/src/assets/fonts/SourceSansPro/SourceSansPro-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@iway/spa-components/src/assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@iway/spa-components/src/assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@iway/spa-components/src/assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:SourceSansPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300;font-style:normal}@font-face{font-family:SourceSansPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:SourceSansPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:600;font-style:normal}@font-face{font-family:SourceSansPro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:700;font-style:normal}.style-module_v-application_3uEKN,body{font-family:SourceSansPro,sans-serif!important}a{transition:all .15s linear}", ""]);
// Exports
exports.locals = {
	"color-neutral-10": "#f2f4f6",
	"color-neutral-20": "#dce0e5",
	"color-neutral-30": "#c0c7d1",
	"color-neutral-40": "#9fa9b9",
	"color-neutral-50": "#848f9f",
	"color-neutral-60": "#67707d",
	"color-neutral-70": "#4c535d",
	"color-neutral-80": "#353a41",
	"color-neutral-90": "#22262b",
	"color-neutral-100": "#131619",
	"color-komis-10": "#eef4ff",
	"color-komis-20": "#cee1ff",
	"color-komis-30": "#a2c9ff",
	"color-komis-40": "#5cadff",
	"color-komis-50": "#0093ec",
	"color-komis-60": "#0074bb",
	"color-komis-70": "#005d98",
	"color-komis-80": "#003c64",
	"color-komis-90": "#002745",
	"color-komis-100": "#00172b",
	"color-crzp-antiplag-10": "#faf1f2",
	"color-crzp-antiplag-20": "#f3dada",
	"color-crzp-antiplag-30": "#e29395",
	"color-crzp-antiplag-40": "#e29395",
	"color-crzp-antiplag-50": "#dd6b6e",
	"color-crzp-antiplag-60": "#ba4b4e",
	"color-crzp-antiplag-70": "#903235",
	"color-crzp-antiplag-80": "#471416",
	"color-crzp-antiplag-90": "#471416",
	"color-crzp-antiplag-100": "#2c0a0b",
	"color-science-evaluation-10": "#f7f2fa",
	"color-science-evaluation-20": "#eadaf3",
	"color-science-evaluation-30": "#dbbce9",
	"color-science-evaluation-40": "#c896dd",
	"color-science-evaluation-50": "#b873d2",
	"color-science-evaluation-60": "#9a51b3",
	"color-science-evaluation-70": "#733b86",
	"color-science-evaluation-80": "#51285f",
	"color-science-evaluation-90": "#371941",
	"color-science-evaluation-100": "#210d29",
	"color-scidap-10": "#f4f3fa",
	"color-scidap-20": "#e0ddf3",
	"color-scidap-30": "#c7c2e9",
	"color-scidap-40": "#aaa1dd",
	"color-scidap-50": "#9084d2",
	"color-scidap-60": "#7360c2",
	"color-scidap-70": "#5641a6",
	"color-scidap-80": "#3c2c77",
	"color-scidap-90": "#281c53",
	"color-scidap-100": "#170f35",
	"color-skcris-10": "#f2f3fd",
	"color-skcris-20": "#dbdef9",
	"color-skcris-30": "#bec3f4",
	"color-skcris-40": "#9aa3ee",
	"color-skcris-50": "#7986e8",
	"color-skcris-60": "#4e64df",
	"color-skcris-70": "#3f51b5",
	"color-skcris-80": "#263377",
	"color-skcris-90": "#182153",
	"color-skcris-100": "#0c1234",
	"color-iss-10": "#ebf5ff",
	"color-iss-20": "#c5e3ff",
	"color-iss-30": "#8aceff",
	"color-iss-40": "#00adef",
	"color-iss-50": "#0098d2",
	"color-iss-60": "#0077a6",
	"color-iss-70": "#00587c",
	"color-iss-80": "#003e58",
	"color-iss-90": "#00293c",
	"color-iss-100": "#001825",
	"color-creuc-10": "#e2f8f9",
	"color-creuc-20": "#c1e7e9",
	"color-creuc-30": "#92d1d5",
	"color-creuc-40": "#70b4b8",
	"color-creuc-50": "#5e989b",
	"color-creuc-60": "#49777a",
	"color-creuc-70": "#34585a",
	"color-creuc-80": "#233d3f",
	"color-creuc-90": "#16292a",
	"color-creuc-100": "#0a1818",
	"color-svd-10": "#e9f7ec",
	"color-svd-20": "#beeac7",
	"color-svd-30": "#8cd79d",
	"color-svd-40": "#6ab97d",
	"color-svd-50": "#599c69",
	"color-svd-60": "#457b52",
	"color-svd-70": "#315b3c",
	"color-svd-80": "#214028",
	"color-svd-90": "#142a19",
	"color-svd-100": "#09190d",
	"color-cip-vvi-10": "#eef7dc",
	"color-cip-vvi-20": "#d7e5ba",
	"color-cip-vvi-30": "#b8d07d",
	"color-cip-vvi-40": "#9cb169",
	"color-cip-vvi-50": "#839558",
	"color-cip-vvi-60": "#677544",
	"color-cip-vvi-70": "#4c5731",
	"color-cip-vvi-80": "#343c20",
	"color-cip-vvi-90": "#222814",
	"color-cip-vvi-100": "#131709",
	"color-open-access-10": "#f9f2ed",
	"color-open-access-20": "#f0dccb",
	"color-open-access-30": "#e1c1a2",
	"color-open-access-40": "#cea06a",
	"color-open-access-50": "#af8758",
	"color-open-access-60": "#8a6a44",
	"color-open-access-70": "#664e31",
	"color-open-access-80": "#483621",
	"color-open-access-90": "#302314",
	"color-open-access-100": "#1d1409",
	"color-crepc-10": "#f6f3f1",
	"color-crepc-20": "#e8ded8",
	"color-crepc-30": "#d7c2b6",
	"color-crepc-40": "#bfa391",
	"color-crepc-50": "#a1897a",
	"color-crepc-60": "#7f6c60",
	"color-crepc-70": "#5e4f46",
	"color-crepc-80": "#423730",
	"color-crepc-90": "#2c241f",
	"color-crepc-100": "#1a1411",
	"v-application": "style-module_v-application_3uEKN"
};
module.exports = exports;
