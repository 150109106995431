

















import { Component, Prop, Vue } from "vue-property-decorator";
import { IHistoryItem } from "@/api/stimulus.api";
import { IDatatableOptions } from "@/api/types";
import { KomisHeading4, KomisTable } from "@iway/spa-components";
import { DataTableHeader } from "vuetify";

@Component({
    components: { KomisTable, KomisHeading4 },
})
export default class StateHistoryTable extends Vue {
    @Prop({ required: true, type: Array }) items!: Array<IHistoryItem>;

    historyTable: IDatatableOptions & { items: IHistoryItem[] } = {
        headers: [],
        items: [],
        loading: false,
        count: 0,
        limit: 100,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$i18n.t("history.state") as string,
                sortable: true,
                value: "name",
            },
            {
                text: this.$i18n.t("history.modify_date") as string,
                sortable: true,
                value: "startDate",
            },
            {
                text: this.$i18n.t("history.comment") as string,
                sortable: false,
                value: "ceReason",
            },
        ];
    }

    created() {
        this.historyTable.headers = this.tableHeaders;
        this.historyTable.items = this.items;
    }

    mounted() {
        //
    }
}
