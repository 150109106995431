var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('komis-content-header',{attrs:{"history":"","module":'skcris',"title":_vm.$i18n.t('evaluations.list'),"breadcrumbs-items":_vm.breadcrumbItems}}),_c('komis-table',_vm._b({key:"table--evaluations",attrs:{"module":"skcris","countPagesItem":Math.ceil(_vm.evaluationsDatatable.count / _vm.evaluationsDatatable.limit),"hasType":false,"headers":_vm.evaluationsDatatable.headers,"items":_vm.evaluationsDatatable.items,"loading":_vm.evaluationsDatatable.loading,"locale":'sk',"multi-sort":true,"option":_vm.evaluationsDatatable.options,"totalItems":_vm.evaluationsDatatable.count,"item-key":"id"},on:{"update:option":function($event){return _vm.$set(_vm.evaluationsDatatable, "options", $event)},"optionChanged":_vm.onDatatableOptionsChanged},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center flex-nowrap"},[_c('komis-action-button',{attrs:{"tooltip-text":_vm.$i18n.t('evaluations.datatable.detail'),"type":"show"},on:{"click":function($event){_vm.$router.push(_vm.getDetailRoute(item))}}}),(_vm.userCanEdit(item) && item.state.code === _vm.EEvaluationStates.Vytvorena)?_c('komis-action-button',{attrs:{"tooltip-text":_vm.$i18n.t('stimulus.datatable.form'),"type":"edit"},on:{"click":function($event){return _vm.netgrifFormRoute(item)}}}):_c('komis-action-button',{attrs:{"tooltip-text":_vm.$i18n.t('stimulus.datatable.download'),"type":"download"},on:{"click":function($event){return _vm.downloadPdf(item.id)}}}),_c('komis-action-button',{attrs:{"type":"attachment","tooltip-text":_vm.$i18n.t('evaluations.datatable.documents')},on:{"click":function($event){_vm.$router.push(_vm.getDocumentsListRoute(item))}}}),(_vm.userCanReopen && item.state.code === _vm.EEvaluationStates.Uzavreta)?_c('komis-action-button',{attrs:{"type":"back","tooltip-text":_vm.$i18n.t('evaluations.datatable.open')},on:{"click":function($event){return _vm.openEvaluation(item)}}}):_vm._e(),(
                        (_vm.stimulusEvaluationsRoute || _vm.allEvaluationsRoute) &&
                        _vm.userCanReopen &&
                        item.state.code === _vm.EEvaluationStates.Vytvorena
                    )?_c('komis-action-button',{attrs:{"type":"delete","tooltip-text":_vm.$i18n.t('evaluations.datatable.delete')},on:{"click":function($event){return _vm.deleteEvaluation(item)}}}):_vm._e()],1)]}}],null,true)},'komis-table',_vm.$attrs,false)),_c('komis-dialog',_vm._g(_vm._b({ref:"confirmDeleteDialog",attrs:{"module":"skcris","title":_vm.$i18n.t('modals.confirm_delete.title'),"action-button-text":_vm.$i18n.t('modals.confirm_delete.button')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var model = ref.model;
return [_vm._v(" "+_vm._s(model)+" ")]}}])},'komis-dialog',_vm.$attrs,false),_vm.$listeners)),(_vm.stimulusEvaluationsRoute && _vm.userCanAdd)?_c('div',{staticClass:"buttons mb-8"},[_c('komis-button',{staticClass:"mr-2",attrs:{"type":"outlined","module":"skcris","value":_vm.$i18n.t('evaluations.btn.generate'),"disabled":_vm.generateDisable},on:{"click":function($event){return _vm.generateTasks()}}}),_c('komis-button',{attrs:{"type":"outlined","href":_vm.newEvaluationLink,"module":"skcris","value":_vm.$i18n.t('evaluations.new.title')}})],1):_vm._e(),(_vm.error)?_c('komis-alert',{attrs:{"type":"error","text":_vm.$i18n.t('creating_items_failed')}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }