/*
1) Ensure you have json module config in your project tsconfig:
{
  "compilerOptions": {
    ...
    "resolveJsonModule": true,
    ...

2) Create /src/locales/LOCALE.json files.
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import sk from "@/locales/sk.json";
import en from "@/locales/en.json";
import libEn from "@iway/spa-components/src/locales/en.json";
import libSk from "@iway/spa-components/src/locales/sk.json";

Vue.use(VueI18n);

// https://kazupon.github.io/vue-i18n/guide/lazy-loading.html

export const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "sk",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sk",
    //messages: loadLocaleMessages(),
    messages: { sk: { ...libSk, ...sk }, en: { ...libEn, ...en } },
});
export default i18n;

const loadedLanguages = [process.env.VUE_APP_I18N_FALLBACK_LOCALE]; // our default language that is preloaded

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html")?.setAttribute("lang", lang);
    return lang;
}

export function loadLanguageAsync(lang: string): Promise<string> {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(async (messages) => {
        const libLang = await import(/* webpackChunkName: "lang-[request]" */ `@iway/spa-components/src/locales/${lang}.json`)
            .then((libMessages) => libMessages)
            .catch((e) => console.error("library lang not loaded, error: ", e))
            .catch(() => null);
        i18n.setLocaleMessage(lang, { ...libLang?.default, ...messages.default });
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });

    // TODO: load vuetify locales async ??
}
