import Vue from "vue";
import { getDocumentDetail, IFileDetail } from "@/api/documents.api";

export interface IDocumentDetailState {
    id: number | null;
    detail: IFileDetail | null;
    loaded: boolean;
    loading: boolean;
}

export const stimulusDocument: IDocumentDetailState = Vue.observable({
    id: null,
    loaded: false,
    loading: false,
    detail: null,
});

export const loadDocumentDetail = (id: number) => {
    // skip already loaded document
    if ((stimulusDocument.id === id && stimulusDocument.loaded) || stimulusDocument.loading) {
        return;
    }

    stimulusDocument.loaded = false;
    stimulusDocument.loading = true;
    stimulusDocument.id = id;

    getDocumentDetail(id)
        .then((documentDetail) => (stimulusDocument.detail = documentDetail))
        .catch((error) => {
            stimulusDocument.detail = null;
            console.error(error);
        })
        .finally(() => {
            stimulusDocument.loaded = true;
            stimulusDocument.loading = false;
        });
};
