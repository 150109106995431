













import { Component } from "vue-property-decorator";
import { KomisButton, KomisContentHeader } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import StimulusTable from "@/components/stimulus-list/StimulusTable.vue";
import StimulusDetail from "@/components/stimulus-detail/StimulusDetail.vue";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";

@Component({
    components: { StimulusDetail, StimulusTable, KomisContentHeader, KomisButton },
})
export default class StimulusDetailView extends BaseView {
    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationStimulusDetail;
    }

    mounted() {
        if (this.organizationRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.stimulus_list").toString(),
                to: {
                    name: RouteNames.OrganizationStimulusList,
                    params: { id: this.$route.params.organizationId },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: {
                    name: RouteNames.OrganizationStimulusDetail,
                    params: { organizationId: this.$route.params.organizationId, id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list").toString(),
                to: { name: RouteNames.StimulusList },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: { name: RouteNames.StimulusDetail, params: { id: this.$route.params.id } },
                disabled: false,
                link: true,
                exact: true,
            });
        }
    }

    get backButtonRoute() {
        return this.organizationRoute
            ? {
                  name: RouteNames.OrganizationStimulusList,
                  params: { id: this.$route.params.organizationId },
              }
            : { name: RouteNames.StimulusList };
    }

    get newStimulusLink(): string | false {
        return getNetgrifUrlByParams("btn_ziadost_o_novy_stimul", "SKCRIS-028", {}, this.$store.state.language.value);
    }
}
