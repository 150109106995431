
//import { Utils } from "@/mixins/Utils";

import { Component, Vue } from "vue-property-decorator";
import { loadLanguageAsync } from "@/i18n";
import { ILoggedUser } from "@iway/spa-components/types";

@Component({
    components: {},
    //mixins: [Utils],
})
export default class BaseLayout extends Vue {
    vuetifySetted = false;
    languageSet = false;

    get envLoaded(): boolean {
        return !!this.$store.state.envParams;
    }

    get loggedUser(): ILoggedUser {
        return window.sspaStore.loggedUser;
    }

    async mounted() {
        // language
        const language = this.$route.params.language || "sk";
        this.$store.dispatch("setLanguage", language);
        console.log("route lang", this.$route.params.language, language);
        await loadLanguageAsync(language).then(() => {
            this.$i18n.locale = language;
            console.log("language $i18n: ", this.$i18n.locale);
            console.log("language $store: ", this.$store.state.language);
            this.$vuetify.lang.current = this.$i18n.locale;
            this.languageSet = true;
        });
    }

    closeNotification(data: never): void {
        this.$store.dispatch("closeNotification", data);
    }
}
