import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import sk from "vuetify/lib/locale/sk";
import en from "vuetify/src/locale/en";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";

Vue.use(Vuetify);

// https://vuetifyjs.com/en/features/icon-fonts/#font-awesome-pro-icons
Vue.component("font-awesome-icon", FontAwesomeIcon);

library.add(
    fas, // TODO: import only used icons
);
export default new Vuetify({
    lang: {
        locales: { sk, en },
        current: "sk",
    },
    icons: {
        iconfont: "faSvg",
        defaultSet: "custom",
        values: {
            sort: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-arrow-down"] } },
            dropdown: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-caret-down"] } },
            cart: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-shopping-cart"] } },
            download: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-download"] } },
            plus: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-plus"] } },
            pencil: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-pencil"] } },
            close: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-times"] } },
            delete: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-times"] } },
            account: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-user"] } },
            calendar: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-calendar"] } },
            menu: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-bars"] } },
            "chevron-right": { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-chevron-right"] } },
            "chevron-left": { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-chevron-left"] } },
            // complete: "...",
            // cancel: "...",
            // clear: "...",
            // success: "...",
            // info: "...",
            // warning: "...",
            // error: "...",
            // prev: "...",
            // next: "...",
            checkboxOn: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-check-square"] } },
            checkboxOff: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-square"] } },
            // checkboxIndeterminate: "...",
            // delimiter: "...",
            // expand: "...",
            // menu: "...",
            // subgroup: "...",
            radioOn: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-dot-circle"] } },
            radioOff: { component: FontAwesomeIcon, props: { icon: ["fa-solid", "fa-circle"] } },
            // radioOn: "...",
            // radioOff: "...",
            // edit: "...",
            // ratingEmpty: "...",
            // ratingFull: "...",
            // ratingHalf: "...",
            // loading: "...",
            // first: "...",
            // last: "...",
            // unfold: "...",
            // file: "...",
        },
    },
    theme: {
        options: { customProperties: true },
    },
});
