import Vue from "vue";
import { AxiosResponse } from "axios";
import { api, ECategoryCode, ICategorySmRequestStatusResponse, IStimulusState } from "@/api/stimulus.api";

const stimulusStates = Vue.observable({ states: [] as IStimulusState[] });

let pendingPromise: Promise<any> | null = null;

export const getStimulusStates = async (): Promise<IStimulusState[]> => {
    if (!stimulusStates.states.length) {
        pendingPromise = api
            .get(`/api/v1/certification/categories/${ECategoryCode.StateForm}`)
            .then((response: AxiosResponse<ICategorySmRequestStatusResponse>) => {
                stimulusStates.states = response.data.data;
                return stimulusStates.states;
            })
            .finally(() => (pendingPromise = null));
        return pendingPromise;
    } else {
        return stimulusStates.states;
    }
};
