import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('komis-accordion',{attrs:{"items":[{ title: _vm.$i18n.t('documents.upload.title'), expanded: false }]},scopedSlots:_vm._u([{key:"item-content-0",fn:function(){return [_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('komis-upload-files',{attrs:{"label":_vm.$i18n.t('documents.upload.label')},on:{"change":_vm.onFileChange}}),(!_vm.forcePublicState)?_c('komis-checkbox',{attrs:{"label":_vm.$i18n.t('file_upload.meta.is_public')},model:{value:(_vm.fileReference.isPublic),callback:function ($$v) {_vm.$set(_vm.fileReference, "isPublic", $$v)},expression:"fileReference.isPublic"}}):_vm._e(),_c('komis-heading5',{attrs:{"text":_vm.$i18n.t('file_upload.meta.lang')}}),_c(VBtnToggle,{model:{value:(_vm.fileReference.metadata.lang),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "lang", $$v)},expression:"fileReference.metadata.lang"}},[_c(VBtn,{attrs:{"outlined":"","small":"","value":"sk"}},[_vm._v(_vm._s(_vm.$i18n.t("file_upload.meta.sk")))]),_c(VBtn,{attrs:{"outlined":"","small":"","value":"en"}},[_vm._v(_vm._s(_vm.$i18n.t("file_upload.meta.en")))])],1),_c('komis-accordion',{attrs:{"items":[{ title: _vm.$i18n.t('file_upload.meta.title'), expanded: true }]},scopedSlots:_vm._u([{key:"item-content-0",fn:function(){return [_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.name') + ' *',"required":"","rules":[
                                    function (v) { return !!v || _vm.$i18n.t('file_upload.meta.validation.not_empty'); },
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.name),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "name", $$v)},expression:"fileReference.metadata.name"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.keywords'),"rules":[
                                    //(v) => !!v || $i18n.t('file_upload.meta.validation.not_empty'),
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.keywords),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "keywords", $$v)},expression:"fileReference.metadata.keywords"}})],1)],1),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.author') + ' *',"rules":[
                                    function (v) { return !!v || _vm.$i18n.t('file_upload.meta.validation.not_empty'); },
                                    function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); } ],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.author),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "author", $$v)},expression:"fileReference.metadata.author"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.coauthor'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.coauthor),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "coauthor", $$v)},expression:"fileReference.metadata.coauthor"}})],1)],1),_c(VRow,[_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.copyright'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.copyright),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "copyright", $$v)},expression:"fileReference.metadata.copyright"}})],1),_c(VCol,[_c('komis-text-field',{attrs:{"label":_vm.$i18n.t('file_upload.meta.source'),"rules":[function (v) { return v.length <= _vm.maxLength || _vm.$i18n.t('file_upload.meta.validation.maxLength'); }],"counter":_vm.maxLength},model:{value:(_vm.fileReference.metadata.source),callback:function ($$v) {_vm.$set(_vm.fileReference.metadata, "source", $$v)},expression:"fileReference.metadata.source"}})],1)],1)]},proxy:true}])}),_c('komis-button',{attrs:{"loading":_vm.loading,"disabled":_vm.loading || !_vm.valid,"module":"skcris","value":_vm.$i18n.t('documents.upload.button')},on:{"click":_vm.uploadFiles}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }