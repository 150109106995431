import store from "@/store";

export enum ETypeCodes {
    Certificates = "certificates",
    //GrantsProjects = "grantsProject",
}

export const getPdfLink = (id: string, type: string): string => {
    const baseLink = store.state.envParams?.["komis.vue.config.reports.api.url"] || null;
    const langCode = store.state.language.valueForApi;
    return baseLink + `/api/v1/reports/form/pdf/${type}/${id}?langCode=${langCode}`;
};
