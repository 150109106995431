




//import { Utils } from "@/mixins/Utils";

import { Component, Vue } from "vue-property-decorator";
import axios, { AxiosError } from "axios";
import { KomisAlert, KomisLayout, KomisNotifications, KomisVuetifySetter } from "@iway/spa-components";
import store from "@/store";

@Component({
    components: { KomisVuetifySetter, KomisLayout, KomisNotifications, KomisAlert },
})
export default class App extends Vue {
    beforeCreate() {
        window.addEventListener("onSaveEnvParams", () => {
            store.commit("setEnvParams", window.sspaStore.envParams);
            console.log("envParams:", window.sspaStore.envParams);
            axios.defaults.baseURL = window.sspaStore.envParams["komis.vue.config.project.api.url"];
            axios.defaults.withCredentials = true;
            console.log("axios.defaults.baseURL: " + axios.defaults.baseURL);
        });
        window.addEventListener("onSaveLoggedUser", () => {
            this.$store.dispatch("saveLoggedUser", window.sspaStore.loggedUser);
            this.$store.commit("setUserLoaded", true);

            axios.interceptors.response.use(
                (response) => response,
                (err: AxiosError) => {
                    //const originalRequest = err.config;
                    if (err.response?.status === 403) {
                        // Forbidden
                        console.log("interceptor error 403");
                        if (!window.sspaStore.loggedUser.authenticated) {
                            //sspaStore.keycloak.login();
                            window.dispatchEvent(new Event("onLogin"));
                        }
                    }
                    if (err.response?.status === 401 && !window.sspaStore.loggedUser.authenticated) {
                        // Not logged
                        // Unauthorized
                        console.log("interceptor error 401");
                        //sspaStore.keycloak.login();
                        window.dispatchEvent(new Event("onLogin"));
                    }
                    return Promise.reject(err);
                },
            );
        });
    }
}
