





























import { Component } from "vue-property-decorator";
import BaseLayout from "@/components/layouts/BaseLayout.vue";
import { KomisLayout, KomisNotifications, KomisVuetifySetter } from "@iway/spa-components";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";

@Component({
    components: {
        KomisVuetifySetter,
        KomisNotifications,
        KomisLayout,
    },
})
export default class SidebarLayout extends BaseLayout {
    setColors(colors: VuetifyThemeVariant): void {
        console.log("vuetify colors", colors, this.$vuetify.theme.default, this.$vuetify.theme.currentTheme);
        this.$vuetify.theme.themes.light = colors;
        this.vuetifySetted = true;
    }
}
