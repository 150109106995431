import Vue from "vue";

export interface IEvaluationsListFilterOptions {
    orgname: string | null; // nazov organizacie
    evaluatorname: string | null; // hodnotiteľ
    state: string | null; // IEvaluationsState.id => uuid
    datefrom: string | null; // datum odoslania od (filter na skModifyDate)
    dateto: string | null; // datum odoslania do (filter na skModifyDate)
}

export const initialEvaluationsFilter = (): IEvaluationsListFilterOptions => {
    return {
        orgname: null, // nazov organizacie
        evaluatorname: null, // hodnotiteľ
        state: null, // stav ziadosti
        datefrom: null, // datum odoslania od
        dateto: null, // datum odoslania do
    };
};

export const evaluationsFilter: IEvaluationsListFilterOptions = Vue.observable(initialEvaluationsFilter());

export const evaluationsFilterForApi = (): string | undefined => {
    const filterForApi: string[] = [];
    Object.keys(evaluationsFilter).forEach((keyName) => {
        const value = evaluationsFilter[keyName];
        if (value || (typeof value === "string" && value.length)) {
            filterForApi.push(`${keyName}(${value})`);
        }
    });
    return filterForApi.length ? filterForApi.join(",") : undefined;
};
