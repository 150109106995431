




















































































































import { Component, Watch } from "vue-property-decorator";
import { KomisActionButton, KomisButton, KomisContentHeader, KomisDialog, KomisTable, KomisAlert } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import { evaluationsFilter, IEvaluationsListFilterOptions } from "@/components/evaluations/evaluationsFilter";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import { IDatatableOptions } from "@/api/types";
import {
    deleteTask,
    EStimulusStateCodes,
    getEvaluationPdfLink,
    getStimulusDetail,
    getStimulusEvaluations,
    ICertificationDetail,
    IEvaluation,
    IStimulusEvaluationsDatatableResponse,
    setTaskState,
    ITask,
    IStimulusEvaluator,
    IAttachmentType,
    getSmReviewTypes,
    getSmProjectTemplates,
    postTask,
} from "@/api/stimulus.api";
import { EEvaluationStates } from "@/api/evaluations-states";
import { getStimulusEvaluators } from "@/api/stimulus-evaluators";
import { USER_ROLE, userHasRole, VALIDATION_TYPE } from "@/helpers/user";
import { DataOptions } from "vuetify";
import { getEvaluationsDetail, IStimulusState } from "@/api/stimulus.api";
import { getEvaluationsStates } from "@/api/evaluations-states";

@Component({
    components: { KomisContentHeader, KomisButton, KomisActionButton, KomisTable, KomisDialog, KomisAlert },
})
export default class EvaluationsListView extends BaseView {
    evaluationsDatatable: IDatatableOptions & { items: IEvaluation[] } = {
        headers: [
            {
                text: this.$i18n.t("evaluations.datatable.date") as string,
                sortable: true,
                value: "createDate",
            },
            {
                text: this.$i18n.t("evaluations.datatable.evaluator") as string,
                sortable: true,
                value: "evaluatorName",
            },
        ],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    error = false;
    allEvaluators: IStimulusEvaluator[] = [];
    allSmReviewTypes: IAttachmentType[] = [];
    allSmProjectTemplates: IAttachmentType[] = [];
    generateDisable = true;

    stimulusDetail: ICertificationDetail | false = false;
    getEvaluationPdfLink = getEvaluationPdfLink;
    EEvaluationStates = EEvaluationStates;
    createdState: IStimulusState | undefined = undefined;

    get userCanReopen() {
        return userHasRole([USER_ROLE.SpravcaAgendyStimulyVav]);
    }

    get userCanAdd() {
        return this.stimulusDetail
            ? this.stimulusDetail.states[0].code === EStimulusStateCodes.OdoslanyNaHodnotenie &&
                  userHasRole([USER_ROLE.SpravcaAgendyStimulyVav])
            : false;
    }

    userCanEdit(item: IEvaluation) {
        if (this.stimulusEvaluationsRoute) {
            if (this.$store.state.loggedUser.userId == item.evaluatorId) return true;
            return userHasRole([USER_ROLE.Hodnotitel, USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.HodnotitelSKCRIS], VALIDATION_TYPE.AllOf);
        }

        const isOnlyEvaluator = userHasRole(USER_ROLE.Hodnotitel) && !userHasRole(USER_ROLE.SpravcaAgendyStimulyVav);
        return isOnlyEvaluator && this.$store.state.loggedUser.userId == item.evaluatorId;
    }

    get stimulusEvaluationsRoute() {
        return this.$route.name === RouteNames.StimulusEvaluations;
    }

    get allEvaluationsRoute() {
        return this.$route.name === RouteNames.AllEvaluations;
    }

    get requestId() {
        return this.$route.params.id;
    }

    mounted() {
        if (this.stimulusEvaluationsRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list") as string,
                to: {
                    name: RouteNames.StimulusList,
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail") as string,
                to: {
                    name: RouteNames.StimulusDetail,
                    params: { id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list") as string,
                disabled: false,
                link: false,
                exact: true,
            });

            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.evaluatorType") as string,
                sortable: false,
                value: "evaluatorType",
            });

            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.evaluation") as string,
                sortable: false,
                value: "actions",
            });

            this.loadStimulusDetail();
            getSmReviewTypes()
                .then((smReviewTypes) => {
                    console.log("smReviewTypes", smReviewTypes);
                    this.allSmReviewTypes = smReviewTypes;
                })
                .finally(() => console.log("allSmReviewTypes:", this.allSmReviewTypes));

            getStimulusEvaluators()
                .then((evaluators) => {
                    this.allEvaluators = evaluators;
                })
                .finally(() => console.log("allEvaluators:", this.allEvaluators));

            getSmProjectTemplates()
                .then((smProjectTemplates) => {
                    this.allSmProjectTemplates = smProjectTemplates;
                })
                .finally(() => console.log("allSmProjectTemplates:", this.allSmProjectTemplates));
            this.generateDisable = false;
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list") as string,
                disabled: false,
                link: false,
                exact: true,
            });
            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.requestor") as string,
                sortable: true,
                value: "requestor", // zatial nechodi z API
            });

            this.evaluationsDatatable.headers.push({
                text: this.$i18n.t("evaluations.datatable.actions") as string,
                sortable: false,
                value: "actions",
            });
        }

        getEvaluationsStates()
            .then((states) => {
                this.createdState = states.find(state => state.name === "Vytvorená");
            });

        this.loadDataFromApi();
    }

    get backButtonRoute() {
        return { name: RouteNames.StimulusDetail, params: { id: this.$route.params.id } };
    }

    get newEvaluationLink(): string | false {
        return this.$router.resolve({ name: RouteNames.StimulusEvaluationsNew }).href;
    }

    netgrifFormRoute(item: IEvaluation) {
        getEvaluationsDetail(item.id)
            .then((evaluationsDetail) => {
                window.location.assign(
                    getNetgrifUrlByParams(
                        "zobrazenie_hodnotenia_sposobilosti",
                        "SKCRIS-026",
                        { orgId: evaluationsDetail.organization.id, caseId: item.uri },
                        this.$store.state.language.value,
                    ) as string,
                );
            })
            .catch((error) => (console.log(error)))
    }

    getDetailRoute(item: IEvaluation) {
        return this.stimulusEvaluationsRoute
            ? { name: RouteNames.StimulusEvaluationsDetail, params: { id: this.$route.params.id, evaluationId: item.id, evaluatorId: item.evaluatorId } }
            : { name: RouteNames.EvaluationsDetail, params: { evaluationId: item.id, evaluatorId: item.evaluatorId } };
    }

    getDocumentsListRoute(item: IEvaluation) {
        return this.stimulusEvaluationsRoute
            ? {
                  name: RouteNames.StimulusEvaluationsDocuments,
                  params: { id: this.$route.params.id, evaluationId: item.id, evaluatorId: item.evaluatorId },
              }
            : { name: RouteNames.EvaluationsDocuments, params: { evaluationId: item.id, evaluatorId: item.evaluatorId } };
    }

    loadDataFromApi() {
        this.loadEvaluations(this.evaluationsDatatable.options);
    }

    loadEvaluations(options: DataOptions) {
        this.evaluationsDatatable.loading = true;
        const paginationOptions = {
            ...options,
        };
        this.$emit("paginationOptionsChanged", paginationOptions);
        getStimulusEvaluations(paginationOptions, this.requestId)
            .then((datatableResponse) => {
                this.processDatatableResponse(datatableResponse);
            })
            .finally(() => (this.evaluationsDatatable.loading = false));
    }

    loadStimulusDetail() {
        getStimulusDetail(this.$route.params.id).then((stimulusDetail) => (this.stimulusDetail = stimulusDetail));
    }

    processDatatableResponse(datatableResponse: IStimulusEvaluationsDatatableResponse) {
        this.evaluationsDatatable.items = datatableResponse.data;
        this.evaluationsDatatable.count = datatableResponse.count;
    }

    onDatatableOptionsChanged(options: DataOptions) {
        this.loadEvaluations(options);
    }

    submitEvaluation(item: IEvaluation) {
        console.log("Submit: (Zatial chýba api!)", item);
    }

    openEvaluation(item: IEvaluation) {
        console.log("Open: (Zatial chýba!)", item);
        if (this.createdState)
        setTaskState(item.id, this.createdState.id)
            .then((response) => {
                console.log(response);
            })
            .catch((err: any) => {
                console.error("setProjectState:", err);
            })
            .finally(() => this.loadDataFromApi());
    }

    deleteEvaluation(item: IEvaluation) {
        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(this.$i18n.t("modals.confirm_delete.task").toString())
            .then(() => {
                deleteTask(item.id, item.uri).then(() => {
                    this.loadEvaluations(this.evaluationsDatatable.options);
                });
            });
    }

    get filter(): IEvaluationsListFilterOptions {
        return evaluationsFilter;
    }
    @Watch("filter", { deep: true })
    onFilterChanged() {
        this.loadEvaluations(this.evaluationsDatatable.options);
    }

    downloadPdf(id: string) {
        window.open(getEvaluationPdfLink(id));
    }

    generateTasks() {
        if (this.allEvaluators) {
            let tasks: ITask[] = [];
            this.allEvaluators.forEach((item: IStimulusEvaluator) => {
                let task: ITask = {
                    id: null,
                    formId: "",
                    evaluatorTypeId: "",
                    evaluatorId: "",
                    templateId: "",
                    templateCode: "",
                    uri: "",
                };

                task.evaluatorId = item.id;
                task.evaluatorTypeId = this.allSmReviewTypes[0].id;
                task.templateId = this.allSmProjectTemplates[0].id;
                task.templateCode = this.allSmProjectTemplates[0].code;
                tasks.push(task);
            });
            console.log("Tasks: ", tasks);
            this.generateDisable = true;
            postTask(tasks, this.$route.params.id).then((response) => {
                if (response.success) {
                    this.loadEvaluations(this.evaluationsDatatable.options);
                    this.generateDisable = false;
                } else if (response.error) {
                    console.log("Error:", response.error);
                    this.error = true;
                }
            });
        }
    }
}
