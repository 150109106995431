


























































import { Component, Prop, Vue } from "vue-property-decorator";
import { getStimulusDetail, ICertificationDetail, EStimulusStateCodes } from "@/api/stimulus.api";
import { KomisButton, KomisKeyValueList, KomisHeading2, KomisDialog } from '@iway/spa-components';
import { RouteNames } from "@/router";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import StimulusStateChange from "@/components/stimulus-detail/StimulusStateChange.vue";
import StateHistoryTable from "@/components/stimulus-detail/StateHistoryTable.vue";
import CertificationOrganizationsTable from "@/components/stimulus-detail/CertificationOrganizationsTable.vue";

@Component({
    components: {
        StimulusStateChange,
        KomisKeyValueList,
        KomisButton,
        StateHistoryTable,
        CertificationOrganizationsTable,
        KomisHeading2,
        KomisDialog
    },
})
export default class StimulusDetail extends Vue {
    @Prop({ required: true, type: String }) stimulusId!: string;

    RouteNames = RouteNames;
    stimulusDetail: ICertificationDetail | false = false;
    loading = true;
    error = false;
    historyEnabled = false;
    componentKey = 0;
    EStimulusStateCodes = EStimulusStateCodes;

    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationStimulusDetail;
    }

    get readonly() {
        return !userHasRole(USER_ROLE.SpravcaAgendyStimulyVav);
    }

    get documentsListRoute() {
        if (this.organizationRoute) {
            return {
                name: RouteNames.OrganizationDocuments,
                params: { organizationId: this.$route.params.organizationId, id: this.stimulusId },
            };
        } else {
            return { name: RouteNames.Documents, params: { id: this.stimulusId } };
        }
    }

    get detailInfoItems() {
        if (!this.stimulusDetail) {
            return [];
        }

        return [
            { key: this.$i18n.t("detail.certNumber").toString(), value: this.stimulusDetail.certNumber },
            { key: this.$i18n.t("detail.postingDate").toString(), value: this.stimulusDetail.postingDate },
            { key: this.$i18n.t("detail.validFrom").toString(), value: this.stimulusDetail.validFrom },
            { key: this.$i18n.t("detail.validTo").toString(), value: this.stimulusDetail.validTo },
            { key: this.$i18n.t("detail.issueDate").toString(), value: this.stimulusDetail.issueDate },
        ];
    }

    async created() {
        this.loadData();
    }

    loadData() {
        this.loading = true;
        getStimulusDetail(this.stimulusId)
            .then((stimulusDetail) => (this.stimulusDetail = stimulusDetail))
            .catch(() => (this.error = true))
            .finally(() => {
                this.loading = false;
                this.historyEnabled = true;
                console.log("History Loaded..", this.stimulusDetail);
                this.componentKey += 1;
            });
    }
}
