






import { Component, Vue } from "vue-property-decorator";
import { IBreadcrumbsItem, KomisContentHeader } from "@iway/spa-components";
import { ISspaStore } from "@iway/spa-components/types";
import { RouteNames } from "@/router";

@Component({
    components: { KomisContentHeader },
})
export default class BaseView extends Vue {
    breadcrumbItems: IBreadcrumbsItem[] = [];

    mounted() {
        this.breadcrumbItems.unshift({
            text: this.$i18n.t("app_name").toString(),
            to: { name: RouteNames.Home },
            disabled: false,
            link: true,
            exact: true,
        });
    }
}
