

































import { Component, Vue, Watch } from "vue-property-decorator";
import { KomisButton, KomisSelect, KomisSidebarHeader, KomisTextField } from "@iway/spa-components";
import Navigation from "@/components/Navigation.vue";
import { initialStimulusFilter, IStimulusListFilterOptions, stimulusFilter } from "@/components/stimulus-list/stimulusFilter";
import { EStimulusStateCodes, IStimulusState } from "@/api/stimulus.api";
import { getStimulusStates } from "@/api/stimulus-states";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import { cloneDeep, isEqual } from "lodash";
import { RouteNames } from "@/router";

@Component({
    components: {
        Navigation,
        KomisTextField,
        KomisButton,
        KomisSidebarHeader,
        KomisSelect,
    },
})
export default class StimulusListSidebar extends Vue {
    filter: IStimulusListFilterOptions = initialStimulusFilter();

    // stimulus state filter select
    statesLoading = true;
    allStates: IStimulusState[] = [];

    get allowFilter(): boolean {
        return (
            // userHasRole([USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.SpravcaAgendyStimulyVav], VALIDATION_TYPE.OneOf) &&
            !this.$route.params.organizationId
        );
    }

    get disableStateSelect(): boolean {
        // return userHasRole(USER_ROLE.ClenKomisieStimulyVav);
        return false;
    }

    get states(): IStimulusState[] {
        // clenKomisie? Show only state "Podana"
        // if (userHasRole(USER_ROLE.ClenKomisieStimulyVav)) {
        //     return castArray(this.allStates.find((state) => state.code === EStimulusStateCodes.Podana)) || [];
        // }
        if (this.$route.name === RouteNames.StimulusList && userHasRole(USER_ROLE.Hodnotitel)) {
            if (userHasRole(USER_ROLE.SpravcaAgendyStimulyVav)) return this.allStates;
            
            const excludedValues: EStimulusStateCodes[] = [EStimulusStateCodes.Rozpracovany, EStimulusStateCodes.VratenyNaDoplnenie, EStimulusStateCodes.Odstraneny];
            this.allStates = this.allStates.filter(state => !excludedValues.includes(state.code!));
        }
        return this.allStates;
    }

    get stimulusFilter() {
        return stimulusFilter;
    }

    created() {
        // load stimulus states
        this.statesLoading = true;
        getStimulusStates()
            .then((states) => {
                this.allStates = states;
            })
            .finally(() => (this.statesLoading = false));
    }

    @Watch("stimulusFilter", { deep: true })
    onStimulusFilterChanged(newFilter: IStimulusListFilterOptions) {
        if (!isEqual(cloneDeep(this.filter), cloneDeep(newFilter))) {
            this.filter = cloneDeep(newFilter);
        }
    }

    async mounted() {
        await this.$nextTick();
        if (stimulusFilter) this.filter = cloneDeep(stimulusFilter);
    }

    filterResults() {
        // Sync stimulusFilter with local component filter
        stimulusFilter.name = this.filter.name;
        stimulusFilter.state = this.filter.state;
    }

    clearFilter() {
        this.filter = initialStimulusFilter();
        this.$nextTick(() => this.filterResults());
    }
}
