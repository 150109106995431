import Vue from "vue";
import Vuex from "vuex";
import { commonComponentsStore } from "@iway/spa-components";
import { ILoggedUser } from "@iway/spa-components/types";

Vue.use(Vuex);

export interface LanguageState {
    value: null | string;
    valueForApi: null | string;
}

export interface IEnvParams {
    "komis.vue.keycloak.fe.url": string;
    "komis.vue.config.iss.api.url": string;
    "komis.vue.keycloak.fe.clientId": string;
    "komis.vue.keycloak.fe.realm": string;
}

export interface AppState {
    notifications: any[];
    language: LanguageState;
    envParams: IEnvParams | null;

    loggedUser: ILoggedUser;
    userLoaded: boolean;
}

const initialState: AppState = {
    notifications: [],
    language: { value: null, valueForApi: null },
    envParams: null,
    loggedUser: { authenticated: false },
    userLoaded: false,
};

export const store = new Vuex.Store<AppState>({
    state: initialState,
    modules: {
        //register Vuex store module
        iwaylib: commonComponentsStore,
    },

    getters: {
        appModule: (state) => {
            if (state.envParams) {
                return state.envParams["komis.vue.modul_instance"];
            } else {
                return "skcris";
            }
        },
    },

    actions: {
        showNotification({ state }, data) {
            state.notifications.push(data);
        },
        closeNotification({ state }, data) {
            const index = state.notifications.findIndex((e) => e == data);
            state.notifications.splice(index, 1);
        },

        setLanguage({ state }, language) {
            state.language.value = language;
            state.language.valueForApi = language + "_" + language.toUpperCase();
        },

        saveLoggedUser({ state }, data) {
            state.loggedUser = data;
        },
    },

    mutations: {
        setEnvParams(state, envParams) {
            state.envParams = envParams;
        },
        setUserLoaded(state, userLoaded) {
            state.userLoaded = userLoaded;
        },
    },
});

export default store;
