import Vue from "vue";

export interface IStimulusListFilterOptions {
    name: string | null; // nazov organizacie
    state: string | null; // IStimulusState.id => uuid
}

export const initialStimulusFilter = (): IStimulusListFilterOptions => {
    return {
        name: null, // nazov organizacie
        state: null, // stav ziadosti
    };
};

export const stimulusFilter: IStimulusListFilterOptions = Vue.observable(initialStimulusFilter());

export const prepareFilterForApi = (): string | undefined => {
    const filterForApi: string[] = [];
    Object.keys(stimulusFilter).forEach((keyName) => {
        const value = stimulusFilter[keyName];
        if (value || (typeof value === "string" && value.length)) {
            filterForApi.push(`${keyName}(${value})`);
        }
    });
    return filterForApi.length ? filterForApi.join(",") : undefined;
};
