export const isOriginFromPersonalSpace = () => {
    const ORIGIN_API_KEY = "isOrganizationOriginPersonalSpace";
    const isFromPersonalSpace = sessionStorage.getItem(ORIGIN_API_KEY) === "true";
    return isFromPersonalSpace;
};

export const Utils = {
    methods: {
        isOriginFromPersonalSpace,
    },
};

export default Utils;
